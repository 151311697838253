<!-- 详情弹窗 -->
<template>
  <a-modal
    title="详情"
    :width="640"
    :footer="null"
    :visible="visible"
    @update:visible="updateVisible"
  >
    <a-form
      class="ud-form-detail"
      :label-col="{ sm: { span: 8 }, xs: { span: 6 } }"
      :wrapper-col="{ sm: { span: 16 }, xs: { span: 18 } }"
    >
      <a-row :gutter="16">
        <a-col :sm="12" :xs="24">
          <!-- 所属运营商 -->
          <a-form-item label="所属运营商:">
            <div class="ud-text-secondary">
              {{ data.operatorName }}
            </div>
          </a-form-item>
          <!-- 厂家 -->
          <a-form-item label="厂家:">
            <div class="ud-text-secondary">
              {{ data.brandName }}
            </div>
          </a-form-item>
          <!-- 型号 -->
          <a-form-item label="型号:">
            <div class="ud-text-secondary">
              {{ data.model }}
            </div>
          </a-form-item>
          <!-- 机柜尺寸 -->
          <a-form-item label="机柜尺寸:">
            <div class="ud-text-secondary">
              {{ data.cabinetSize }}
            </div>
          </a-form-item>
        </a-col>
        <a-col :sm="12" :xs="24">
          <!-- 仓位尺寸 -->
          <a-form-item label="仓位尺寸:">
            <div class="ud-text-secondary">
              {{ data.warehouseSize }}
            </div>
          </a-form-item>
          <!-- 屏幕尺寸 -->
          <a-form-item label="屏幕尺寸:">
            <div class="ud-text-secondary">
              {{ data.screenSize }}
            </div>
          </a-form-item>
          <!-- 仓位数 -->
          <a-form-item label="仓位数:">
            <div class="ud-text-secondary">
              {{ data.number }}
            </div>
          </a-form-item>
          <!-- 防水等级 -->
          <a-form-item label="防水等级:">
            <div class="ud-text-secondary">
              {{ data.grade }}
            </div>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </a-modal>
</template>

<script>
export default {
  name: 'brandDetail',
  emits: ['update:visible'],
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 数据
    data: Object
  },
  methods: {
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value)
    }
  }
}
</script>

<style scoped>
</style>
