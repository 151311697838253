<!-- 编辑弹窗 -->
<template>
  <a-modal
    :width="680"
    :visible="visible"
    :confirm-loading="loading"
    :title="isUpdate ? '修改充电柜型号' : '新建充电柜型号'"
    :body-style="{ paddingBottom: '8px' }"
    @update:visible="updateVisible"
    @ok="save"
  >
    <a-form
      ref="form"
      :model="form"
      :rules="rules"
      :label-col="{ md: { span: 7 }, sm: { span: 24 } }"
      :wrapper-col="{ md: { span: 17 }, sm: { span: 24 } }"
    >
      <a-row :gutter="16">
        <a-col :md="12" :sm="24" :xs="24">
          <a-form-item label="所属运营商:" name="operatorId">
            <a-select
              v-model:value="form.operatorId"
              placeholder="请选择所属运营商"
              allow-clear
            >
              <a-select-option
                v-for="item in operatorList"
                :key="item.operatorId"
                :value="item.operatorId"
              >
                {{ item.operatorName }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="厂家:" name="brandName">
            <a-input
              v-model:value="form.brandName"
              placeholder="请输入厂家"
              allow-clear
            />
          </a-form-item>
          <a-form-item label="型号:" name="model">
            <a-input
              v-model:value="form.model"
              placeholder="请输入型号"
              allow-clear
            />
          </a-form-item>
          <a-form-item label="机柜尺寸:" name="cabinetSize">
            <a-input
              v-model:value="form.cabinetSize"
              placeholder="请输入机柜尺寸"
              allow-clear
              suffix="mm*mm*mm"
            >
            </a-input>
          </a-form-item>
        </a-col>
        <a-col :md="12" :sm="24" :xs="24">
          <a-form-item label="仓位数:" name="number">
            <a-input
              v-model:value="form.number"
              placeholder="请输入仓位数"
              allow-clear
            />
          </a-form-item>
          <a-form-item label="仓位尺寸:" name="warehouseSize">
            <a-input
              v-model:value="form.warehouseSize"
              placeholder="请输入仓位尺寸"
              allow-clear
              suffix="mm*mm*mm"
            />
          </a-form-item>
          <a-form-item label="屏幕尺寸:" name="screenSize">
            <a-input
              v-model:value="form.screenSize"
              placeholder="请输入仓位尺寸"
              allow-clear
              suffix="寸"
            />
          </a-form-item>
          <a-form-item label="防水等级:" name="grade">
            <a-input
              v-model:value="form.grade"
              placeholder="请输入防水等级"
              allow-clear
            />
          </a-form-item>
          <!-- <a-form-item label="图片:" name="photo">
            <a-upload
              list-type="picture-card"
              class="avatar-uploader"
              v-model:file-list="fileList"
              @preview="handlePreview"
              :customRequest="({ file }) => uploadFile(file, 'photo')"
            >
              <div v-if="this.fileList.length < 1">
                <plus-outlined />
                <div class="ant-upload-text">上传</div>
              </div>
            </a-upload>
            <a-modal
                :visible="previewVisible"
                :footer="null"
                @cancel="handleCancel"
              >
                <img alt="example" style="width: 100%" :src="imageUrl" />
              </a-modal>
          </a-form-item> -->
        </a-col>
      </a-row>
    </a-form>
  </a-modal>
</template>

<script>
import * as operatorApi from '@/api/rnt/operator'
import * as chargeCabinetModelApi from '@/api/rnt/chargeCabinetModel'
import setting from '@/config/setting'
// import { PlusOutlined } from '@ant-design/icons-vue'
// import regions from '@/utils/regions'
// function getBase64(file) {
//   return new Promise((resolve, reject) => {
//     const reader = new FileReader()
//     reader.readAsDataURL(file)
//     reader.onload = () => resolve(reader.result)
//     reader.onerror = (error) => reject(error)
//   })
// }
export default {
  name: 'brandEdit',
  emits: ['done', 'update:visible'],
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object
  },
  components: {
    // PlusOutlined
  },
  data() {
    return {
      // 预览图片对话框显示隐藏
      // previewVisible: false,
      // 图片列表
      // fileList: [],
      // 图片预览地址
      // imageUrl: '',
      // 图片上传地址
      // uploadUrl: setting.uploadUrl,
      // 表单数据
      pageData: {},
      headers: {},
      // 表单数据
      form: Object.assign({}, this.data, {
        modulesArray: [],
        indeterminate: false,
        checkAll: false
      }),
      // 编辑弹窗表单验证规则
      rules: {
        operatorId: [
          {
            required: true,
            message: '请输入所属运营商',
            type: 'number',
            trigger: 'blur'
          }
        ],
        model: [
          {
            required: true,
            message: '请输入型号',
            type: 'string',
            trigger: 'blur'
          }
        ],
        number: [
          {
            required: true,
            message: '请输入仓位数',
            type: 'string',
            trigger: 'blur'
          }
        ],
        brandName: [
          {
            required: true,
            message: '请输入厂家',
            type: 'string',
            trigger: 'blur'
          }
        ]
      },
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,
      operatorList: [],
      pointTypesList: []
    }
  },
  watch: {
    data() {
      if (this.data) {
        this.form = this.data
        const fullCity = [
          this.form.province,
          this.form.city,
          this.form.district
        ]
        this.form.fullCity = fullCity
        this.isUpdate = true
      } else {
        this.form = {}
        this.isUpdate = false
      }
      if (this.$refs.form) {
        this.$refs.form.clearValidate()
      }
    }
  },
  mounted() {
    this.headers[setting.tokenHeaderName] = setting.getToken()
    this.queryOperatorList()
    // this.queryPointType()
  },
  methods: {
    // 取消预览
    // handleCancel() {
    //   this.previewVisible = false
    // },
    // 查看预览图片
    // async handlePreview(file) {
    //   if (!file.url && !file.preview) {
    //     file.preview = await getBase64(file.originFileObj)
    //   }
    //   this.imageUrl = file.url || file.preview
    //   this.previewVisible = true
    // },
    // 自定义上传图片函数
    // uploadFile(file, name) {
    //   const formData = new FormData()

    //   formData.append('file', file)
    //   const hide = this.$message.loading('上传中..', 0)
    //   this.$http
    //     .post(this.uploadUrl + '/file/upload', formData)
    //     .then((res) => {
    //       hide()
    //       if (res.data.code === 0) {
    //         if (name === 'photo') {
    //           this.fileList[0] = {
    //             uid: '-4',
    //             name: res.data.fileName,
    //             status: 'done',
    //             url: res.data.location
    //           }
    //         } else {
    //           this.$message.error(res.data.msg)
    //         }
    //         this.form[name] = res.data.location
    //       }
    //     })
    //     .catch((e) => {
    //       hide()
    //       this.$message.error(e.message)
    //     })
    //   return false
    // },
    /* 保存编辑 */
    save() {
      this.$refs.form
        .validate()
        .then(() => {
          this.loading = true
          chargeCabinetModelApi
            .save(this.form, this.isUpdate)
            .then((res) => {
              this.loading = false
              if (res.code === 0) {
                this.$message.success(res.msg)
                if (!this.isUpdate) {
                  this.form = {}
                }
                this.updateVisible(false)
                this.$emit('done')
              } else {
                this.$message.error(res.msg)
              }
            })
            .catch((e) => {
              this.loading = false
              this.$message.error(e.message)
            })
        })
        .catch(() => {})
    },
    created() {
      this.initData()
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value)
    },
    /* 查询所属运营商List */
    queryOperatorList() {
      operatorApi
        .all()
        .then((res) => {
          if (res.code === 0) {
            this.operatorList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    checkAllChange(e) {
      Object.assign(this.form, {
        modulesArray: e.target.checked ? this.moduleOptions : [],
        indeterminate: false
      })
    }
  }
}
</script>

<style scoped>
.photo {
  width: 100%;
}
</style>
