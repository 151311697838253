<template>
  <div class="ud-body">
    <!-- 搜索表单 -->
    <a-card :bordered="true">
      <a-form
        :model="where"
        :label-col="{ md: { span: 8 }, sm: { span: 24 } }"
        :wrapper-col="{ md: { span: 16 }, sm: { span: 24 } }"
      >
        <a-row>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="所属运营商:">
              <a-select
                v-model:value="where.operatorId"
                placeholder="请选择所属运营商"
                @change="changeOperator"
                allow-clear
              >
                <a-select-option
                  v-for="item in operatorList"
                  :key="item.operatorId"
                  :value="item.operatorId"
                >
                  {{ item.operatorName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="厂家:">
              <a-select
                v-model:value="where.brandName"
                placeholder="请选择厂家"
                @change="handleBrandChange"
                allow-clear
              >
                <a-select-option
                  v-for="item in brandList"
                  :key="item.brandId"
                  :value="item.brandName"
                >
                  {{ item.brandName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="型号:">
              <a-select
                v-model:value="where.model"
                placeholder="请选择型号"
                allow-clear
              >
                <a-select-option
                  v-for="item in modelList"
                  :key="item.brandId"
                  :value="item.model"
                >
                  {{ item.model }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item class="ud-text-right" :wrapper-col="{ span: 24 }">
              <a-space>
                <a-button type="primary" @click="reload">查询</a-button>
                <a-button @click="reset">重置</a-button>
              </a-space>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <!-- 表格 -->
      <ud-pro-table
        ref="table"
        row-key="modelId"
        :datasource="datasource"
        :columns="columns"
        v-model:selection="selection"
        :scroll="{ x: 'max-content' }"
      >
        <template #toolbar>
          <a-space>
            <a-button type="primary" @click="openEdit()">
              <template #icon>
                <plus-outlined />
              </template>
              <span>新建</span>
            </a-button>
            <a-button type="primary" danger @click="removeBatch">
              <template #icon>
                <delete-outlined />
              </template>
              <span>删除</span>
            </a-button>
          </a-space>
        </template>
        <template #action="{ record }">
          <a-space>
            <a @click="openDetail(record)">详情</a>
            <a @click="openEdit(record)">修改</a>
            <a-divider type="vertical" />
            <a-popconfirm
              title="确定要删除此项目吗？"
              @confirm="remove(record)"
            >
              <a class="ud-text-danger">删除</a>
            </a-popconfirm>
          </a-space>
        </template>
      </ud-pro-table>
    </a-card>
    <!-- 编辑弹窗 -->
    <brand-edit v-model:visible="showEdit" :data="current" @done="reload" />
  </div>
  <!-- 详情弹窗 -->
  <brand-detail v-model:visible="showInfo" :data="current || {}" />
</template>

<script>
import * as chargeCabinetModelApi from '@/api/rnt/chargeCabinetModel'
import brandEdit from './brand-edit.vue'
import brandDetail from './brand-detail.vue'
import { createVNode } from 'vue'
import {
  PlusOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined
} from '@ant-design/icons-vue'
import * as operatorApi from '@/api/rnt/operator'
// import OperRecordDetail from './oper-record-detail'

export default {
  name: 'brand',
  components: {
    PlusOutlined,
    DeleteOutlined,
    brandEdit,
    brandDetail
  },
  data() {
    return {
      checked1: false,
      name: 'chargeCabinet',
      // 表格列配置
      columns: [
        {
          key: 'index',
          width: 48,
          customRender: ({ index }) => this.$refs.table.tableIndex + index
        },
        // 所属运营商
        {
          title: '所属运营商',
          dataIndex: 'operatorName',
          sorter: true
        },
        // 厂家
        {
          title: '厂家',
          dataIndex: 'brandName',
          sorter: true,
          slots: { customRender: 'brandName' }
        },
        // 型号
        {
          title: '型号',
          dataIndex: 'model',
          sorter: true
        },
        // 机柜尺寸
        {
          title: '机柜尺寸',
          dataIndex: 'cabinetSize',
          sorter: true
        },
        // 仓位数
        {
          title: '仓位数',
          dataIndex: 'number',
          sorter: true
        },
        // 仓位尺寸
        {
          title: '仓位尺寸',
          dataIndex: 'warehouseSize',
          sorter: true
        },
        // 屏幕尺寸
        {
          title: '屏幕尺寸',
          dataIndex: 'screenSize',
          sorter: true
        },
        // 防水等级
        {
          title: '防水等级',
          dataIndex: 'grade',
          sorter: true
        },
        // 操作
        {
          title: '操作',
          key: 'action',
          width: 200,
          align: 'center',
          slots: { customRender: 'action' }
        }
      ],
      operatorList: [],
      // 厂家list
      brandList: [],
      // 型号list
      modelList: [],
      // 表格搜索条件
      where: {},
      // 表格选中数据
      selection: [],
      // 是否显示编辑弹窗
      showEdit: false,
      // 是否显示详情弹窗
      showInfo: false,
      // 当前编辑数据
      current: null,
      // 编辑表单提交状态
      editLoading: false
    }
  },
  mounted() {
    this.queryOperatorList()
    this.queryBrandList()
    this.queryBatteryModel()
  },
  methods: {
    /* 详情 */
    openDetail(row) {
      this.current = row
      this.showInfo = true
    },
    datasource(option, callback) {
      chargeCabinetModelApi
        .page({
          ...this.where,
          page: option.page,
          limit: option.limit
        })
        .then((res) => {
          if (res.code === 0) {
            callback(res.data, res.count)
          } else {
            callback(res.msg)
          }
        })
        .catch((e) => {
          callback(e.message)
        })
    },
    /* 刷新表格 */
    reload() {
      this.selection = []
      this.$refs.table.reload({ page: 1 })
    },
    /* 重置搜索 */
    reset() {
      this.where = {}
      this.queryBrandList()
      this.queryBatteryModel()
      this.$nextTick(() => {
        this.reload()
      })
    },
    /* 删除单个 */
    remove(row) {
      const hide = this.$message.loading('请求中..', 0)
      chargeCabinetModelApi
        .deleteById(row.modelId)
        .then((res) => {
          hide()
          if (res.code === 0) {
            this.$message.success(res.msg)
            this.reload()
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          hide()
          this.$message.error(e.message)
        })
    },
    /* 批量删除 */
    removeBatch() {
      if (!this.selection.length) {
        this.$message.error('请至少选择一条数据')
        return
      }
      this.$confirm({
        title: '提示',
        content: '确定要删除选中的项目吗?',
        icon: createVNode(ExclamationCircleOutlined),
        maskClosable: true,
        onOk: () => {
          const hide = this.$message.loading('请求中..', 0)
          const data = { data: this.selection.map((d) => d.modelId) }
          chargeCabinetModelApi
            .deleteBatch(data)
            .then((res) => {
              hide()
              if (res.code === 0) {
                this.$message.success(res.msg)
                this.reload()
              } else {
                this.$message.error(res.msg)
              }
            })
            .catch((e) => {
              hide()
              this.$message.error(e.message)
            })
        }
      })
    },
    // 运营商改变获取对应品牌列表
    changeOperator(value) {
      this.where.brandName = null
      this.where.model = null
      chargeCabinetModelApi
        .brand({ operatorId: value })
        .then((res) => {
          if (res.code === 0) {
            this.brandList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    /* 查询所属运营商List */
    queryOperatorList() {
      operatorApi
        .all()
        .then((res) => {
          if (res.code === 0) {
            this.operatorList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    /* 查询换电柜厂家List */
    queryBrandList() {
      chargeCabinetModelApi
        .brand()
        .then((res) => {
          if (res.code === 0) {
            this.brandList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    // 获取所有换电柜型号
    queryBatteryModel() {
      chargeCabinetModelApi
        .model()
        .then((res) => {
          if (res.code === 0) {
            this.modelList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    // 监听厂家改变
    handleBrandChange(value) {
      this.where.model = null
      // 查找当前厂家充电柜型号
      chargeCabinetModelApi
        .model({ operatorId: this.where.operatorId, brandName: value })
        .then((res) => {
          if (res.code === 0) {
            this.modelList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    /* 打开编辑弹窗 */
    openEdit(row) {
      this.current = row
      this.showEdit = true
    }
  }
}
</script>

<style scoped>
</style>
